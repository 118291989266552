<template>
  <div>
    <div class="flex justify-start">
      <BaseInput
        class="text-theme-darkest mr-4"
        label="Device ID"
        placeholder="1234"
        v-model="deviceId"
        data-cy="deviceId"
        type="text"
      />
      <div class="w-1/5">
        <p class="text-xs text-black">Device Type</p>
        <select v-model="deviceType" data-cy="deviceType">
          <option
            v-bind:key="type.name"
            v-for="type in $store.getters.deviceTypes"
            v-bind:value="type.name"
            >{{ type.name }}</option
          >
        </select>
      </div>
      <div>
        <button
          @click="createDevice"
          :disabled="isDisabled"
          class="my-6"
          data-cy="create-device"
        >
          <span v-if="loading">{{ loadingMessage }}</span>
          <span v-else>Add Device</span>
        </button>
      </div>
    </div>
    <p class="error" v-if="formError">{{ formError }}</p>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      deviceId: null,
      deviceType: null,
      formError: ''
    };
  },
  created () {
    this.$store.dispatch('updateDeviceTypes');
  },
  methods: {
    async createDevice () {
      this.$store
        .dispatch('createDeviceMutation', {
          deviceId: this.deviceId,
          deviceType: this.deviceType
        })
        .then(res => {
          if (res.data) {
            this.$store.dispatch('getDevicesQuery');
            this.deviceId = '';
            this.deviceType = '';
            this.formError = '';
          } else {
            this.formError = res.errors[0].message;
          }
        })
        .catch(() => {
          this.formError = 'Sorry - There was an error. Please try again.';
        });
    }
  },
  computed: {
    isDisabled () {
      //  if id/type are null
      return !this.deviceId || !this.deviceType;
    },
    loading () {
      return this.$store.getters.loading;
    },
    loadingMessage () {
      return this.$store.getters.loadingStatusMessage;
    }
  }
};
</script>
